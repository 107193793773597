import React from 'react';
import {
  Box,
  Heading,
  Text,
  Stack,
  Link,
  useColorModeValue,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react';
import './Overlay.css';

const Overlay = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  return (
    <Box
      position="relative"
      zIndex="10"
      maxW={isMobile ? "90%" : "60%"}
      width="100%"
      mx="auto"
      mt={isMobile ? "5vh" : "10vh"}
      p={isMobile ? 4 : 6}
      bg="black"
      backdropFilter="blur(10px)"
      borderRadius="lg"
      boxShadow="lg"
      overflowY="auto"
      maxHeight={isMobile ? "75vh" : "80vh"}
    >
      <Stack spacing={isMobile ? 4 : 6}>
        <Heading
          as="h1"
          size={isMobile ? "lg" : "xl"}
          textAlign="center"
          color="orange.400"
          fontWeight="bold"
        >
          ORANGE•PILLS SEASON #1
        </Heading>

        <section className="section">
          <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold">
            <strong>How to Play:</strong>
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            Sacrifice the required BITCOIN RUNE by depositing into the addresses below for the desired result.
          </Text>
        </section>

        <Divider borderColor="orange.400" />

        <section className="section">
          <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold">
            <strong>Subscription Details:</strong>
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            Depositing entitles you to one random low-cap RUNE mint per subscription every day throughout October. This offer is <strong>retroactive</strong>, meaning you will receive mints for the days already passed in the month!
          </Text>
        </section>

        <Divider borderColor="orange.400" />

        <section className="section">
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            You may find a <strong>DAILY•RUNE•SUBSCRIPTION</strong> at:
          </Text>
          <ul>
            <li>
              <Link href="https://unisat.io/runes/market?tick=DAILY%E2%80%A2RUNE%E2%80%A2SUBSCRIPTION" color="blue.400" isExternal>
                Unisat Link
              </Link>
            </li>
          </ul>
        </section>

        <Divider borderColor="orange.400" />

        <section className="section warning-section">
          <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold" color="white">
            <strong>Warning:</strong> Only deposit the <strong>DAILY•RUNE•SUBSCRIPTION</strong>
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Deposit Address:</strong> <br />
            <Text as="span" wordBreak="break-word">
              bc1pps56rs0t7954v7rwqmjw5zt7s7c50cyt7temvjrckm8sjxwsd3hqferv2u
            </Text>
          </Text>
        </section>

        <Divider borderColor="orange.400" />

        <section className="section">
          <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold">
            <strong>Upgrade Subscription Modules:</strong>
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            HODL the <strong>ORANGE•PILLS</strong> Bitcoin Rune for multiplier effects on your <strong>DAILY•RUNE•SUBSCRIPTION</strong> airdrop mints, or sacrifice them to own part of the fabric of the <strong>Bitcoin Matrix</strong>. Enroll in any module to become a founder in our <strong>RUNE•LAUNCH•PAD</strong> and support our exciting initiatives for exciting rewards.
          </Text>
        </section>

        <Divider borderColor="orange.400" />

        <section className="section">
          <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold">
            <strong>Meme•Lord•Venture•Capital</strong> (Comic Relief)
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Deposit Address:</strong> <br />
            <Text as="span" wordBreak="break-word">bc1py4643jq7kvat0hh0vz6gsgh04a9mxxm8x85nlgmjmd9mc8xtc3ps4ddm6e</Text>
          </Text>
        </section>

        <Divider borderColor="orange.400" />

        <section className="section">
          <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold">
            <strong>Schizoid•Venture•Capital</strong> (Health and Sci-Fi)
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Deposit Address:</strong> <br />
            <Text as="span" wordBreak="break-word">bc1py3rnghje2kr7wu8lmv2mw3sd73d5lmdhppsww6qatc3jkv54794slwg069</Text>
          </Text>
        </section>

        <Divider borderColor="orange.400" />

        <section className="section">
          <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold">
            <strong>Cyber•Security•VC</strong> (Security Tools)
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Deposit Address:</strong> <br />
            <Text as="span" wordBreak="break-word">bc1pa2krmc9gnfhkxr0dk59s88hdq5770xntzytt29e7x84hrs7g4hkq2kzcpc</Text>
          </Text>
        </section>

        <Divider borderColor="orange.400" />

        <section className="section">
          <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold">
            <strong>Sacrifice•Venture•Capital</strong> (Esoteric)
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Deposit Address:</strong> <br />
            <Text as="span" wordBreak="break-word">bc1prw3a6w5shd6xqzmwm2mdqwn0lq3yu8t4ekt8rnfxs629fny4723snvwzlj</Text>
          </Text>
        </section>

        <Divider borderColor="orange.400" />

        <section className="section">
          <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold">
            <strong>RUNEBRELLACORP</strong> (Business & Experiments)
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Warning:</strong> Only deposit <strong>ORANGE•PILLS</strong>
          </Text>
          <Text fontSize={isMobile ? "sm" : "md"} color="white">
            <strong>Deposit Address:</strong> <br />
            <Text as="span" wordBreak="break-word">bc1pu0anz2x72e0u2pej2je2xtrgarn37hkejrunv9mu9fh56w3hwsjq7twtyx</Text>
          </Text>
        </section>

        <Divider borderColor="orange.400" />
        <section className="section">
  <Text fontSize={isMobile ? "sm" : "md"} fontWeight="semibold" color="white">
    <strong>Colab??????</strong> (Community Growth)
  </Text>
  <Text fontSize={isMobile ? "sm" : "md"} color="white">
    If you want to distribute your BITCOIN RUNE to our community, contact us at:
    <br />
    <br />
    <Link href="mailto:ORANGEPILLSRUNE@gmail.com" color="blue.400">
      ORANGEPILLSRUNE@gmail.com
    </Link>
  </Text>
  <Text fontSize={isMobile ? "sm" : "md"} color="white">
    <br />
    After approval and vetting, we will organize the distribution.
  </Text>
</section>
      </Stack>
    </Box>
  );
};

export default Overlay;
